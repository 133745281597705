import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

const ZoomButton = ({ title, Icon, onClick }) => (
  <button onClick={onClick} className="button_no_style zoom_button">
    <Tooltip
      title={title}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <IconButton size="medium" className="icon_button">
        <Icon sx={{ color: "white" }} />
      </IconButton>
    </Tooltip>
  </button>
);

const Controls = ({
  zoomIn,
  zoomOut,
  zoomLevel,
  zoomInClicked,
  setZoomLevel,
  setZoomInClicked,
}) => {
  const minZoom = 1;
  const maxZoom = 5;
  const handleZoomIn = () => {
    if (zoomLevel < maxZoom) {
      zoomIn();
      setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 1, maxZoom));
      setZoomInClicked(true);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > minZoom) {
      setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 1, minZoom));
      zoomOut();
    }
  };

  return (
    <>
      {zoomInClicked && zoomLevel > minZoom && (
        <ZoomButton title="Zoom Out" Icon={ZoomOut} onClick={handleZoomOut} />
      )}
      {zoomLevel < maxZoom && (
        <ZoomButton title="Zoom In" Icon={ZoomIn} onClick={handleZoomIn} />
      )}
    </>
  );
};
export default Controls;
