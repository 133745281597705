import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import Intercom from "@intercom/messenger-js-sdk";
import firestoreRepository from "../repositories/firestoreRepository";
import cloudFunctionRepository from "../repositories/cloudFunctionRepository";
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const db = getFirestore(app);
export const analytics = getAnalytics(app);
setUserProperties(analytics, { app: "Connect", debug_mode: true });

async function login(email, password) {
  const r = await auth.signInWithEmailAndPassword(email, password);
}

async function signup(email, password) {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    await sendEmailVerification(user);
    const userData = {
      uid: user.uid,
      email: user.email,
      firstName: user.displayName || "",
      signinMethod: "email",
      phoneModel: "Web",
    };
    await firestoreRepository.createUser(user.uid, userData);
  } catch (error) {
    console.log("Error during signup:", error);
  }
}

export async function getAuthData() {
  return auth.currentUser.getIdTokenResult(/* forceRefresh */ true);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function logout() {
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);

      setLoading(false);
      Intercom({ app_id: "aetvs8qk" });
      if (user) {
        const authToken = await user.getIdToken();
        const intercomHash = await cloudFunctionRepository.getIntercomHash(
          authToken
        );
        const userData = await firestoreRepository.getUserData(user.uid);
        const intercomData = {
          app_id: "aetvs8qk",
          user_id: userData.uid,
          user_hash: intercomHash,
          email: userData.email,
          name: userData.firstName,
          occupation: userData.speciality,
          used_storage:
            userData.sizeImages === undefined &&
            userData.sizeVideos === undefined
              ? "Unknown"
              : parseFloat(
                  ((userData.sizeImages || 0) + (userData.sizeVideos || 0)) /
                    1024 ** 3
                ).toFixed(1),
        };
        Intercom(intercomData);
      } else {
        const intercomData = {
          app_id: "aetvs8qk",
          name: "Doctor",
        };
        Intercom(intercomData);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    signup,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
