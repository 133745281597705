import React, { useEffect, useState } from "react";
import "../../style/dashboard.css";
import { getAuthData, useAuth } from "../../context/AuthContext";
import { useLayoutEffect } from "react";
import firestoreRepository from "../../repositories/firestoreRepository";
import free from "./free.svg";
import { Grid } from "@mui/material";

const FreeUser = () => {
  const { currentUser } = useAuth();
  const [largestSubscriptionId, setLargestSubscriptionId] = useState(0);
  const [isOrgUser, setIsOrgUser] = useState(null);
  const [entityId, setEntityId] = useState(null);

  useEffect(() => {
    getAuthData()
      .then((data) => {
        // Set entityId and isOrgUser based on the user's claims
        if (!data.claims.orgId) {
          setEntityId(currentUser.uid);
          setIsOrgUser(false);
        } else {
          setEntityId(data.claims.orgId);
          setIsOrgUser(true);
        }
      })
      .catch((error) => {
        console.error("Error getting authentication data:", error);
      });
  }, [currentUser]);

  useLayoutEffect(() => {
    const fetchDocument = () => {
      firestoreRepository.getOrgData(entityId).then((data) => {
        setLargestSubscriptionId(data.stripeSubscriptionId || 0);
      });
    };
    fetchDocument();
  }, []);

  return (
    <>
      {largestSubscriptionId === 0 && isOrgUser && (
        <Grid
          container
          style={{
            padding: "40px",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={5}>
            <h1>No active subscription found</h1>
            <div style={{ fontSize: "22px" }}>
              Don't worry, your data is safe and waiting for you. Reactivate
              your subscription to regain full access and continue enjoying the
              benefits of MicroREC Connect.
            </div>
            <Grid item xs={12} marginTop={"2em"}>
              <a
                className="button_no_style contact_us_button"
                href="mailto:support@customsurgical.co"
              >
                Contact us
              </a>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={5.5}
            marginTop={{ xs: 5, md: 0 }}
            marginLeft={{ xs: 0, md: 5 }}
          >
            <img src={free} style={{ maxWidth: "100%" }}></img>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FreeUser;
