import { ca } from "date-fns/locale";
import { storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const __getBucketNameFromDocumentType = (documentTypeIdx) => {
  switch (documentTypeIdx) {
    case 1:
      return "images";
    case 2:
      return "videos";
    default:
      throw new Error("Invalid document type index");
  }
};

const __getImagesStorageRef = (entityId) => {
  return ref(storage, `${entityId}/images`);
};
const __getVideosStorageRef = (entityId) => {
  return ref(storage, `${entityId}/videos`);
};

const __getStorageRef = (entityId, documentTypeIdx) => {
  const bucketName = __getBucketNameFromDocumentType(documentTypeIdx);
  return ref(storage, `${entityId}/${bucketName}`);
};

const __getThumbStorageRef = (entityId, documentTypeIdx) => {
  const bucketName = __getBucketNameFromDocumentType(documentTypeIdx);
  return ref(storage, `${entityId}/${bucketName}/thumbnails`);
};

const __getThumbSessionStorageRef = (entityId, documentTypeIdx) => {
  const bucketName = __getBucketNameFromDocumentType(documentTypeIdx);
  // Videos only have 1 thumbnail folder while images have 2
  const thumbnailsFolder =
    bucketName === "videos" ? "thumbnails" : "thumbnails-session";
  return ref(storage, `${entityId}/${bucketName}/${thumbnailsFolder}`);
};

const __getStorageDocRef = (
  entityId,
  documentTypeIdx,
  assetGlobalId,
  fileExtension
) => {
  const bucketRef = __getStorageRef(entityId, documentTypeIdx);
  return ref(bucketRef, `${assetGlobalId}.${fileExtension}`);
};

const __getThumbStorageDocRef = (
  entityId,
  documentTypeIdx,
  assetGlobalId,
  fileExtension
) => {
  const bucketRef = __getThumbStorageRef(entityId, documentTypeIdx);
  const docName =
    documentTypeIdx === 1
      ? `${assetGlobalId}_200x204.${fileExtension}`
      : `${assetGlobalId}.jpg`;
  return ref(bucketRef, docName);
};

const __getThumbSessionStorageDocRef = (
  entityId,
  documentTypeIdx,
  assetGlobalId,
  fileExtension
) => {
  const bucketRef = __getThumbSessionStorageRef(entityId, documentTypeIdx);
  const docName =
    documentTypeIdx === 1
      ? `${assetGlobalId}_400x350.${fileExtension}`
      : `${assetGlobalId}.jpg`;
  return ref(bucketRef, docName);
};

// const getAssetDownloadUrls = async (assetData, documentTypeIdx) => {
//     const response = documentTypeIdx === 1
//     ? await getDownloadUrlsFromImage(assetData)
//     : await getDownloadUrlsFromVideo(assetData);
//     return response;
// }

const getAssetDownloadUrls = async (
  entityId,
  assetGlobalId,
  documentTypeIdx,
  fileExtension
) => {
  let downloadUrl;
  let thumbnailDownloadUrl;
  let sessionThumbnailDownloadUrl;
  try {
    const assetRef = __getStorageDocRef(
      entityId,
      documentTypeIdx,
      assetGlobalId,
      fileExtension
    );
    downloadUrl = await getDownloadURL(assetRef);
  } catch (error) {
    console.error(
      `Error getting download URL for asset ${assetGlobalId}:`,
      error
    );
    return { success: false, failureType: "missingAsset" };
  }
  try {
    const thumbRef = __getThumbStorageDocRef(
      entityId,
      documentTypeIdx,
      assetGlobalId,
      fileExtension
    );

    const thumbSessionRef = __getThumbSessionStorageDocRef(
      entityId,
      documentTypeIdx,
      assetGlobalId,
      fileExtension
    );

    thumbnailDownloadUrl = await getDownloadURL(thumbRef);
    sessionThumbnailDownloadUrl = await getDownloadURL(thumbSessionRef);
  } catch (error) {
    console.error(
      `Error getting thumbnail URLs for asset ${assetGlobalId}:`,
      error
    );
    return { success: false, failureType: "missingThumbnails" };
  }
  return {
    success: true,
    downloadUrl,
    thumbnailDownloadUrl,
    sessionThumbnailDownloadUrl,
  };
};

const getAssetDownloadUrlsWithDelay = async (
  entityId,
  assetGlobalId,
  documentTypeIdx,
  fileExtension,
  delay
) => {
  await new Promise((resolve) => setTimeout(resolve, delay));
  return getAssetDownloadUrls(
    entityId,
    assetGlobalId,
    documentTypeIdx,
    fileExtension
  );
};

const uploadFile = (entityId, imageGlobalId, documentTypeIdx, fileItem) => {
  const fileExtension = fileItem.type.slice(6);
  // const bucketRef = __getStorageRef(entityId, documentTypeIdx);
  //const storageRef = ref(bucketRef, imageGlobalId);
  const storageRef = __getStorageDocRef(
    entityId,
    documentTypeIdx,
    imageGlobalId,
    fileExtension
  );
  const uploadTask = uploadBytesResumable(storageRef, fileItem);
  return uploadTask;
};

export default {
  getAssetDownloadUrls,
  getAssetDownloadUrlsWithDelay,
  uploadFile,
};
