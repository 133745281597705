import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import activation from "./activation.png";
import axios from "axios";
import { useAuth, getAuthData } from "../../context/AuthContext";
import firestoreRepository from "../../repositories/firestoreRepository";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Subscribe = ({ postName, tag, ...props }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const [error, setError] = useState("");
  const code = urlParams.get("code");
  const [activationCode, setActivationCode] = useState(code);
  const [open, setOpen] = useState(code != null);
  const [authData, setAuthData] = useState({});
  const { currentUser } = useAuth();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    getAuthData()
      .then((result) => {
        setAuthData(result);
      })
      .catch((error) => {
        console.error("Error getting authentication data:", error);
      });
  }, [currentUser]);

  const location = useLocation();
  const currentPage = location.pathname;

  const createLicenseSession = async () => {
    if (loading) {
      return;
    }
    const params = new URLSearchParams();
    params.append("activationCode", activationCode);
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/activateLicenseCode`,
        params, // Sending the parameters as x-www-form-urlencoded
        {
          headers: {
            Authorization: `Bearer ${authData.token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      // Handle the response
      if (response.data.success === true) {
        // Delete code from URL
        const url = new URL(window.location.href);
        url.searchParams.delete("code");
        window.history.replaceState({}, document.title, url.toString());
        //Redirect to success page
        window.location.href = "/success";
      }
    } catch (error) {
      // Handle errors
      setError(error.response.data);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const [largestSubscriptionId, setLargestSubscriptionId] = useState();

  useLayoutEffect(() => {
    setIsLoading(true);
    firestoreRepository.getUserData(currentUser.uid).then((userData) => {
      setLargestSubscriptionId(
        Math.max(
          userData.stripeSubscriptionId || 0,
          userData.iosSubscriptionId || 0,
          userData.androidSubscriptionId || 0,
          userData.premiumAndroid ? 2 : 0,
          userData.premiumIOS ? 2 : 0
        )
      );
      setIsLoading(false);
    });
  }, []);

  if (!isMounted) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div style={{ textAlign: "end" }}>
        {largestSubscriptionId > 0 && currentPage !== "/manage" ? (
          ""
        ) : (
          <button
            onClick={() => setOpen(true)}
            style={{
              backgroundColor: "white",
              padding: currentPage === "/manage" ? "0" : "10px 20px",
              borderRadius: "20px",
              fontWeight: currentPage === "/manage" ? "700" : "600",
              fontSize: currentPage === "/manage" ? "16px" : "20px",
              textDecoration: currentPage === "/manage" ? "underline" : "none",
              color: currentPage === "/manage" ? "#8F4FFF" : "#8F4FFF",
              marginTop: currentPage === "/manage" ? "0" : "1em",
              border: "none",
            }}
          >
            Activate now
          </button>
        )}

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"lg"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box display={{ sm: "block", md: "block", xs: "none" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "white",
                    marginBottom: "0em",
                  }}
                >
                  <img src={activation} style={{ width: "50%" }}></img>{" "}
                  <div style={{ padding: "20px" }}>
                    <h2>Enter your License Activation Code</h2>
                    <p>
                      Do you have a license activation code to get access to
                      MicroREC Connect?
                    </p>
                    <p>You can introduce it here:</p>
                    <input
                      list="browsers"
                      type="text"
                      placeholder="Your code"
                      style={{
                        width: "8em",
                        fontFamily: "Red Hat Display",
                        fontSize: "20px",
                      }}
                      className="calendar_button margin_top_wrap"
                      value={activationCode}
                      onChange={(e) => setActivationCode(e.target.value)}
                    ></input>
                    {error ? (
                      <div
                        style={{
                          color: "red",
                          marginTop: "1em",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        {error}
                      </div>
                    ) : (
                      ""
                    )}
                    {loading ? (
                      <div
                        style={{
                          marginTop: "1em",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Loading...
                      </div>
                    ) : (
                      ""
                    )}
                    <button
                      onClick={createLicenseSession}
                      disabled={loading}
                      style={{
                        backgroundColor: loading ? "#ccc" : "#8F4FFF",
                        padding: "10px 25px",
                        borderRadius: "20px",
                        fontWeight: "600",
                        fontSize: "20px",
                        textDecoration: "none",
                        color: "white",
                        marginTop: "1em",
                        border: "none",
                      }}
                    >
                      Activate now
                    </button>
                  </div>
                </div>
              </Box>
              <Box display={{ sm: "none", md: "none", xs: "block" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "white",
                    marginBottom: "0em",
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    <h2>Enter your License Activation Code</h2>
                    <p>
                      Do you have a license activation code to get access to
                      MicroREC Connect?
                    </p>
                    <p>You can introduce it here:</p>
                    <input
                      list="browsers"
                      type="text"
                      placeholder="Your code"
                      style={{
                        width: "8em",
                        fontFamily: "Red Hat Display",
                        fontSize: "20px",
                      }}
                      className="calendar_button margin_top_wrap"
                      value={activationCode}
                      onChange={(e) => setActivationCode(e.target.value)}
                    ></input>
                    {error ? (
                      <div
                        style={{
                          color: "red",
                          marginTop: "1em",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        {error}
                      </div>
                    ) : (
                      ""
                    )}
                    <button
                      onClick={createLicenseSession}
                      disabled={loading}
                      style={{
                        backgroundColor: loading ? "#ccc" : "#8F4FFF",
                        padding: "10px 25px",
                        borderRadius: "20px",
                        fontWeight: "600",
                        fontSize: "20px",
                        textDecoration: "none",
                        color: "white",
                        marginTop: "1em",
                        border: "none",
                      }}
                    >
                      Activate now
                    </button>
                  </div>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default Subscribe;
