import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, MenuItem } from "@mui/material";
import { auth } from "../../firebase";
import { EmailAuthProvider } from "firebase/auth";
import Alert from "@mui/material/Alert";
import "../../style/account.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ChangePassword = () => {
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const handleChangePassword = async () => {
    setError(null);

    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      const regularExpression = /^(?=.*[0-9\W])[a-zA-Z0-9\W]{6,32}$/;
      if (!regularExpression.test(newPassword) === true) {
        setError(
          "The password must be from 6 to 32 characters long and contain at least 1 symbol or number."
        );
      } else if (newPassword !== confirmNewPassword) {
        setError(
          "The new password and confirmation password you entered do not match. Please ensure they are the same and try again."
        );
      } else {
        await user.reauthenticateWithCredential(credential);
        await user.updatePassword(newPassword);
        setSuccess("The password has been changed!");
      }
    } catch (err) {
      if (err.code === "auth/wrong-password") {
        setError(
          "The current password you entered is incorrect. Please try again."
        );
      } else if (err.code === "auth/weak-password") {
        setError(
          "The new password you entered is too weak. Please enter a stronger password."
        );
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      <div>
        <MenuItem
          style={{
            color: "#8F4FFF",
            textDecoration: "underline",
            fontWeight: "700",
            padding: "0",
            justifyContent: "end",
          }}
          onClick={() => setOpen(true)}
        >
          Change Password
        </MenuItem>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"sm"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div className="current_password_div">
                  <div className="current_password_label">
                    <label className="label">Your Current Password</label>
                    <input
                      type="password"
                      required
                      className="email-input"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </div>
                  <div className="new_password_div">
                    <label className="label">Set New Password</label>
                    <input
                      className="email-input"
                      type="password"
                      required
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />{" "}
                  </div>
                  <div className="new_password_div">
                    <label className="label">Confirm New Password</label>
                    <input
                      type="password"
                      required
                      className="email-input"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </div>

                  <div className="cancel_button">
                    <button
                      className="button_no_style font_size_16"
                      onClick={() => {
                        handleClose();
                        setConfirmNewPassword("");
                        setNewPassword("");
                        setCurrentPassword("");
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="button_no_style change_password_button"
                      onClick={() => {
                        handleChangePassword();
                        setConfirmNewPassword("");
                        setNewPassword("");
                        setCurrentPassword("");
                      }}
                    >
                      Change
                    </button>
                  </div>
                  {success && <div className="success_password">{success}</div>}
                  {error && (
                    <Alert
                      severity="error"
                      style={{
                        marginTop: "2em",
                      }}
                    >
                      {error}
                    </Alert>
                  )}
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ChangePassword;
