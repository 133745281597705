import FileSaver from "file-saver";

export const downloadFileWithProgress = (
  file,
  setDownloadFiles,
  setDownloadProgress,
  setDownloadThumbnails,
  setRemainingDownloadTime,
  downloadingFiles,
  setDownloadingFiles
) => {
  setDownloadFiles((prevFiles) => [...prevFiles, file]);
  setDownloadingFiles((prevFiles) => new Set([...prevFiles, file.name]));

  let startTime = Date.now(); // Record the start time of the download

  try {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", file.url);
    xhr.responseType = "blob";
    let downloaded = 0;

    xhr.onprogress = (event) => {
      if (event.lengthComputable && file.size) {
        downloaded = event.loaded;
        const progress = Math.floor(
          Math.min((downloaded / file.size) * 100, 100)
        );
        setDownloadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: progress,
        }));
        setDownloadThumbnails((prevThumbnails) => ({
          ...prevThumbnails,
          [file.name]: file.thumbnail,
        }));
        let elapsedTime = Date.now() - startTime; // Time elapsed in milliseconds
        let downloadSpeed = (downloaded * 1000) / elapsedTime; // Bytes per second
        let remainingData = file.size - downloaded;
        let estimatedTimeLeft = remainingData / downloadSpeed; // Time left in seconds
        let minutesLeft = Math.round(estimatedTimeLeft / 60); // Time left in minutes
        if (progress > 1) {
          setRemainingDownloadTime((prevTimes) => ({
            ...prevTimes,
            [file.name]: minutesLeft,
          }));
        }
      } else {
        setDownloadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: "none",
        }));
        setDownloadThumbnails((prevThumbnails) => ({
          ...prevThumbnails,
          [file.name]: file.thumbnail,
        }));
      }
    };
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        FileSaver.saveAs(blob, file.name);
        setDownloadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 100,
        }));
        setDownloadingFiles(
          (prevFiles) =>
            new Set([...prevFiles].filter((id) => id !== file.name))
        );
      } else {
        console.error("Error downloading file:", xhr.statusText);
      }
    };
    xhr.send();
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
