import React, { useRef, useState } from "react";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import bg1 from "../../images/login/bg1.svg";
import bg2 from "../../images/login/bg2.svg";
import logo from "../../images/dashboard/MicroREC Connect Logo (1).png";
import "../../style/login.css";
import { auth } from "../../firebase";
import { useEffect } from "react";
import firestoreRepository from "../../repositories/firestoreRepository";

export default function Login() {
  const [isMounted, setIsMounted] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const information = code ? `/information?code=${code}` : "/information";
  const dashboard = code ? `?code=${code}` : "/";

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const credential = await auth.signInWithEmailAndPassword(
        emailRef.current.value,
        passwordRef.current.value
      );
      await credential.user.reload();
      if (credential.user.emailVerified === false) {
        setError("You must verify your email first");
        return;
      }
      const userDoc = firestoreRepository.getUserDocument(auth.currentUser.uid);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (
          userData.country !== "" &&
          userData.speciality !== "" &&
          userData.firstName !== ""
        ) {
          navigate(dashboard);
        } else {
          navigate(information);
        }
      }
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError(
          "Error logging in: The user does not exist. Please sign up first."
        );
      } else if (error.code === "auth/wrong-password") {
        setError(
          "Error logging in: The password is incorrect. Please try again."
        );
      } else {
        setError(
          "Error logging in: The user does not exist or has been deleted"
        );
      }
    }
  };

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <div className="login_main">
        <div className="login">
          <div className="bg_login">
            <img src={bg1}></img>
            <img src={bg2}></img>
          </div>
          <div className="login_content">
            <img src={logo} className="logo_login"></img>
            <h2 className="password_h2">Enter Your Password</h2>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleSubmit}>
              <div id="email">
                <input
                  type="email"
                  ref={emailRef}
                  placeholder="Email"
                  required
                  className="email-input"
                ></input>
              </div>
              <div id="password">
                <input
                  type="password"
                  ref={passwordRef}
                  placeholder="Password"
                  required
                  className="email-input"
                ></input>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  disabled={loading}
                  type="submit"
                  className="login-button"
                >
                  Continue
                </button>
              </div>
              <div className="link_forgot margin_top_1">
                {code ? (
                  <a href={`/forgot-password?code=${code}`}>
                    Forgot your password?
                  </a>
                ) : (
                  <a href="/forgot-password">Forgot your password?</a>
                )}{" "}
              </div>
              <div className="no_account_div">
                No account?{" "}
                {code ? (
                  <a href={`/login?code=${code}`}>Create one</a>
                ) : (
                  <a href="/login"> Create one</a>
                )}{" "}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
