import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { auth, SignInWithGoogle, db } from "../../firebase";
import { signInWithApple, analytics } from "../../firebase";
import bg1 from "../../images/login/bg1.svg";
import bg2 from "../../images/login/bg2.svg";
import logo from "../../images/dashboard/MicroREC Connect Logo (1).png";
import google from "../../images/login/google.svg";
import apple from "../../images/login/apple.svg";
import "@fontsource/rubik";
import "../../style/login.css";
import EmailIcon from "@mui/icons-material/Email";
import { logEvent } from "@firebase/analytics";
import firestoreRepository from "../../repositories/firestoreRepository";

function Login() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const information = code ? `/information?code=${code}` : "/information";
  const dashboard = code ? `?code=${code}` : "/";

  async function handleEmail() {
    logEvent(analytics, "user_login_email", {
      button_name: "user_login_email",
    });
    const emailUrl = code ? `/email?code=${code}` : "/email";
    navigate(emailUrl);
  }

  async function handleEmailSignUp() {
    logEvent(analytics, "user_signup_email", {
      button_name: "user_signup_email",
    });
    const signUp = code ? `/sign-up?code=${code}` : "/sign-up";
    navigate(signUp);
  }

  async function handleGoogle(e) {
    logEvent(analytics, "user_login_google", {
      button_name: "user_login_google",
    });
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await SignInWithGoogle();
      const userData = await firestoreRepository.getUserData(
        auth.currentUser.uid
      );
      if (userData.country !== "" && userData.speciality !== "") {
        navigate(dashboard);
      } else {
        navigate(information);
      }
    } catch {
      setError("Failed to log in into account");
    }
    setLoading(false);
  }

  async function handleApple(e) {
    logEvent(analytics, "user_login_apple", {
      button_name: "user_login_apple",
    });
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await signInWithApple();
      const userData = await firestoreRepository.getUserData(
        auth.currentUser.uid
      );
      if (userData.country !== "" && userData.speciality !== "") {
        navigate(dashboard);
      } else {
        navigate(information);
      }
    } catch {
      setError("Failed to log in into account");
    }
    setLoading(false);
  }

  return (
    <>
      <div className="login_main">
        <div className="login">
          <div className="bg_login">
            <img src={bg1}></img>
            <img src={bg2}></img>
          </div>
          <div className="login_content">
            <img src={logo} className="logo_login"></img>
            <h2 className="login_h2">Welcome</h2>
            <p style={{ textAlign: "center" }}>
              Log in to continue with<b> MicroREC Connect</b>.
            </p>
            {error && <Alert severity="error">{error}</Alert>}
            <button onClick={handleEmail} className="email_button">
              <EmailIcon style={{ marginRight: "1em" }}></EmailIcon>
              Continue with email
            </button>
            <button onClick={handleGoogle} className="google">
              <img className="login_icon" src={google}></img>
              Continue with Google
            </button>
            <button onClick={handleApple} className="apple">
              <img className="login_icon" src={apple}></img>
              Continue with Apple
            </button>
            <div className="sign_up_div">
              <hr className="hr_sign_up"></hr>
              OR
              <hr className="hr_sign_up"></hr>
            </div>
            <button className="login-button" onClick={handleEmailSignUp}>
              Sign Up
            </button>
            <a
              href="https://customsurgical.co/connect"
              className="connect_a"
              target="_blank"
            >
              What is MicroREC Connect?
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
