import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import download_select from "../../images/dashboard/download_select.svg";
import download_select_dis from "../../images/dashboard/download_select_dis.svg";
import "../../style/sessionDetailedView.css";
import { UploadContext } from "../App";
import Share from "../common/ModalShareFile";
import Delete from "../common/ModalDeleteFiles";
import { downloadFileWithProgress } from "./downloadFile";
import { Tooltip } from "@mui/material";

const MultiSelect = ({
  entityId,
  isOrgUser,
  multiselectedUrls,
  currentUser,
  reloadCallback,
  multiselectedFiles,
  setMultiselectedFiles,
  sessionId,
  setMultiselectedUrls,
  openedFile,
  setOpenedFile,
  downloadingFiles,
  setDownloadingFiles,
  dltPermission,
  sessionOwner,
  shrPermission,
}) => {
  const { sideBarWidth } = useContext(UploadContext);
  const {
    setDownloadFiles,
    setDownloadProgress,
    setDownloadThumbnails,
    setRemainingDownloadTime,
  } = useContext(UploadContext);

  const handleDownload = (file) => {
    downloadFileWithProgress(
      file,
      setDownloadFiles,
      setDownloadProgress,
      setDownloadThumbnails,
      setRemainingDownloadTime,
      downloadingFiles,
      setDownloadingFiles
    );
  };

  const noDwlPermission =
    shrPermission === 0 ||
    (currentUser !== sessionOwner && shrPermission === 1);

  return (
    <div>
      <div
        className={`multiselect ${
          sideBarWidth === "70px" ? "sidebarWide" : ""
        }`}
      >
        <button
          className="button_no_style close_icon"
          onClick={() => {
            setMultiselectedFiles([]);
          }}
        >
          <CloseIcon />
        </button>
        <div className="font_weight_600">
          Selected ({multiselectedFiles.length})
        </div>
        <div className="flex_auto_margin">
          <Share
            multiselectedUrls={
              Array.isArray(multiselectedUrls)
                ? multiselectedUrls
                : [multiselectedUrls]
            }
            shrPermission={shrPermission}
            sessionOwner={sessionOwner}
            currentUser={currentUser}
          />
          {noDwlPermission ? (
            <Tooltip title="You don't have the required permissions to download this file">
              <button
                className="button_no_style"
                disabled={noDwlPermission}
                style={{ cursor: "auto" }}
              >
                <div className="flex_center_column">
                  <img
                    className="img_height_40"
                    src={download_select_dis}
                  ></img>
                  <div className="save_to_device">Save to Device</div>
                </div>
              </button>
            </Tooltip>
          ) : (
            <button
              className="button_no_style"
              onClick={() => {
                multiselectedFiles.forEach((index) => {
                  if (!downloadingFiles.has(index.globalId)) {
                    const url = index.downloadURL;
                    const name = index.globalId;
                    const thumbnail = index.thumbnailURL;
                    const size = index.size;
                    handleDownload({
                      name: name,
                      url: url,
                      size: size,
                      thumbnail: thumbnail,
                    });
                  }
                });
              }}
            >
              <div className="flex_center_column">
                <img className="img_height_40" src={download_select}></img>
                <div className="save_to_device">Save to Device</div>
              </div>
            </button>
          )}

          <Delete
            entityId={entityId}
            isOrgUser={isOrgUser}
            currentUser={currentUser}
            reloadCallback={reloadCallback}
            multiselectedFiles={multiselectedFiles}
            setMultiselectedFiles={setMultiselectedFiles}
            multiselectedUrls={multiselectedUrls}
            setMultiselectedUrls={setMultiselectedUrls}
            openedFile={openedFile}
            setOpenedFile={setOpenedFile}
            sessionId={sessionId}
            dltPermission={dltPermission}
            sessionOwner={sessionOwner}
          />
        </div>
      </div>{" "}
    </div>
  );
};

export default MultiSelect;
