import React, { useEffect, useState, useRef } from "react";
import "../../style/dashboard.css";
import { useAuth, getAuthData } from "../../context/AuthContext";
import firestoreRepository from "../../repositories/firestoreRepository";
import ModalCreateSession from "../common/ModalSessionCreation";
import devices from "../../images/dashboard/empty_devices.svg";
import qr from "../../images/dashboard/qr.svg";

const EmptyDashboard = () => {
  const [allTags, setAllTags] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const { currentUser } = useAuth();
  const entityId = useRef(null);
  const isOrgUser = useRef(null);

  const loadAllTags = async (userId) => {
    const tags = await firestoreRepository.getAllTags(userId);
    return tags;
  };

  useEffect(() => {
    getAuthData()
      .then((authData) => {
        // Set entityId and isOrgUser based on the user's claims
        if (!authData.claims.orgId) {
          entityId.current = currentUser.uid;
          isOrgUser.current = false;
        } else {
          entityId.current = authData.claims.orgId;
          isOrgUser.current = true;
        }
        setNeedReload(!needReload);
      })
      .catch((error) => {
        console.error("Error getting auth data: ", error);
      });
  }, [currentUser]);

  useEffect(() => {
    loadAllTags(currentUser.uid).then((tags) => {
      setAllTags(tags);
    });
  }, [currentUser.uid]);

  return (
    <>
      <div className="empty_sessions_div">
        <img src={devices} className="width_320"></img>
        <div className="empty_h1">Ready to begin your Connect experience?</div>
        <div className="new_session_div">
          <div className="margin_right_1 empty_text">Click on </div>
          {entityId.current && (
            <ModalCreateSession
              allPatients={[]}
              allTagsArray={allTags}
              entityId={entityId.current}
              isOrgUser={isOrgUser.current}
              checkedOwner={[]}
            />
          )}
          <div className="empty_text">to start</div>
        </div>
        <div className="qr_text">
          Scan the QR code to get the MicroREC app and complete your setup:{" "}
        </div>
        <img src={qr} className="margin_top_1_qr"></img>
      </div>
    </>
  );
};

export default EmptyDashboard;
