import React, { useState } from "react";
import delete_select from "../../images/dashboard/delete_select.svg";
import delete_select_perm from "../../images/dashboard/delete_select_perm.svg";
import "../../style/sessionDetailedView.css";
import { Box, DialogContent, IconButton, styled, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DeleteIcon from "../../images/gallery/delete.svg";
import DeleteIconPerm from "../../images/gallery/delete_perm.svg";
import { useNavigate } from "react-router-dom";
import firestoreRepository from "../../repositories/firestoreRepository";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Delete = ({
  entityId,
  isOrgUser,
  reloadCallback,
  multiselectedFiles,
  setMultiselectedFiles,
  galleryVisible,
  setGalleryVisible,
  multiselectedUrls,
  setMultiselectedUrls,
  sessionId,
  openedFile,
  setOpenedFile,
  dltPermission,
  sessionOwner,
  currentUser,
}) => {
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const deleteMultipleFiles = (filesToDelete) => {
    return new Promise(async (resolve, reject) => {
      try {
        await deleteFiles(entityId, isOrgUser, filesToDelete);
        setMultiselectedFiles([]);
        if (openedFile && openedFile.length > 0) {
          setOpenedFile([]);
        }
        handleClose();
        if (galleryVisible) {
          setGalleryVisible(false);
        }
        if (multiselectedUrls && multiselectedUrls.length > 0) {
          setMultiselectedUrls([]);
        }
        navigate(`/sessions/${sessionId}`);
        reloadCallback();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
  const deleteFiles = async (entityId, isOrgUser, filesToDelete) => {
    for (const media of filesToDelete) {
      if (media.globalId.includes("VIDEO")) {
        firestoreRepository.deleteVideo(entityId, isOrgUser, media.globalId);
      } else {
        firestoreRepository.deleteImage(entityId, isOrgUser, media.globalId);
      }

      firestoreRepository.updateLastDeviceSync(entityId, isOrgUser);
    }
  };

  const noPermission =
    dltPermission === 0 ||
    (currentUser !== sessionOwner && dltPermission === 1);

  return (
    <>
      {galleryVisible === true ? (
        noPermission ? (
          <Tooltip
            title="You don't have the required permissions to delete this file"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton
              size="medium"
              sx={{
                padding: "8px",
                color: "white",
                ":hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                },
              }}
            >
              <button
                className="button_no_style"
                style={{
                  color: "white",
                  margin: "0",
                  padding: "0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={DeleteIconPerm}
                    style={{
                      maxWidth: "100%",
                      width: "24px",
                    }}
                  />
                </div>
              </button>
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            title="Delete"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton
              size="medium"
              sx={{
                padding: "8px",
                color: "white",
                ":hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                },
              }}
            >
              <button
                className="button_no_style"
                onClick={() => {
                  setOpen(true);
                }}
                style={{
                  color: "white",
                  margin: "0",
                  padding: "0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={DeleteIcon}
                    style={{
                      maxWidth: "100%",
                      width: "24px",
                    }}
                  />
                </div>
              </button>
            </IconButton>
          </Tooltip>
        )
      ) : noPermission ? (
        <Tooltip title="You don't have the required permissions to delete this file">
          <button className="button_no_style">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img style={{ height: "40px" }} src={delete_select_perm}></img>
              <div
                style={{
                  color: "#797979",
                  fontSize: "11px",
                  marginTop: "0.5em",
                }}
              >
                Delete
              </div>
            </div>
          </button>
        </Tooltip>
      ) : (
        <button
          className="button_no_style"
          onClick={() => {
            setOpen(true);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img style={{ height: "40px" }} src={delete_select}></img>
            <div
              style={{
                color: "#797979",
                fontSize: "11px",
                marginTop: "0.5em",
              }}
            >
              Delete
            </div>
          </div>
        </button>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"sm"}
        fullWidth={true}
        alignItems="center"
        className="modal-sub"
        style={{ borderRadius: "30px", margin: "0" }}
      >
        <BootstrapDialogContent
          onClose={handleClose}
          dividers
          style={{
            backgroundColor: "#fff",
            padding: "20px",
          }}
        >
          <>
            <Box>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Confirm delete
              </div>
              <p style={{ textAlign: "center" }}>
                Are you sure you want to delete this files?
              </p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "3em",
                }}
              >
                <button
                  className="button_no_style"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>

                <button
                  className="button_no_style"
                  style={{
                    color: "#8F4FFF",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    deleteMultipleFiles(
                      multiselectedFiles && multiselectedFiles.length > 0
                        ? multiselectedFiles
                        : openedFile
                    );
                  }}
                >
                  Delete
                </button>
              </div>
            </Box>
          </>
        </BootstrapDialogContent>
      </BootstrapDialog>
    </>
  );
};
export default Delete;
