import React, { useEffect, useState, useRef } from "react";
import { Box, Modal } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import "../../style/login.css";
import axios from "axios";
import firestoreRepository from "../../repositories/firestoreRepository";

const styleee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 500,
  maxHeight: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  overflow: "auto",
  p: 4,
};

const Account = () => {
  const { currentUser } = useAuth();

  const specialities = [
    "Ophthalmology",
    "Dentistry",
    "Orthopedics",
    "ENT",
    "Neurology",
    "Plastic Surgery",
    "Histopathology",
    "Cardiovascular",
    "Optometrist",
    "Sales Representative",
    "Clinical Specialist",
    "Other",
  ];

  const [error, setError] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userSpeciality, setUserSpeciality] = useState("");
  const [fullName, setFullName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [firestoreData, setFirestoreData] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [countries, setCountries] = useState([]);
  const [displayCountry, setDisplayCountry] = useState("");
  const [displaySpeciality, setDisplaySpeciality] = useState("");
  const [newsletterChecked, setNewsletterChecked] = useState(false);

  const [openNameModal, setOpenNameModal] = useState(false);
  const [openCountryModal, setOpenCountryModal] = useState(false);
  const [openSpecialtyModal, setOpenSpecialtyModal] = useState(false);

  const prevCountry = useRef(null);
  const prevSpeciality = useRef(null);
  const prevName = useRef(null);

  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const dashboard = code ? `/?code=${code}` : "/";

  useEffect(() => {
    firestoreRepository
      .getUserData(currentUser.uid)
      .then((data) => {
        setFullName(`${data?.firstName || ""} ${data?.lastName || ""}`);
        setUserCountry(data?.country);
        setUserSpeciality(data?.speciality);
        prevName.current = `${data?.firstName} ${data?.lastName}`;
        prevCountry.current = data?.country;
        prevSpeciality.current = data?.speciality;
        setFirestoreData(data);
        setIsMounted(true);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const sortedCountries = response.data.sort((a, b) => {
          if (a.name.common < b.name.common) return -1;
          if (a.name.common > b.name.common) return 1;
          return 0;
        });
        setCountries(sortedCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const updateName = (uid, fullName) => {
    const nameArray = fullName.split(" ");
    const data = {
      firstName: nameArray[0],
      lastName: fullName.includes(" ") ? nameArray.slice(1).join(" ") : "",
    };
    firestoreRepository.updateUser(uid, data);
  };

  const updateCountry = (uid, userCountry) => {
    firestoreRepository.updateUser(uid, { country: userCountry });
  };

  const updateSpeciality = (uid, userSpeciality) => {
    firestoreRepository.updateUser(uid, { speciality: userSpeciality });
  };

  const updateNewsletter = (uid, newsletter) => {
    firestoreRepository.updateUser(uid, { newsletter: newsletter });
  };

  const handleCountryChange = () => {
    setUserCountry(userCountry);
    prevCountry.current = userCountry;
    setOpenCountryModal(false);
  };

  const handleSpecialityChange = () => {
    setUserSpeciality(userSpeciality);
    prevSpeciality.current = userSpeciality;
    setOpenSpecialtyModal(false);
  };

  const handleNameChange = () => {
    setFullName(fullName);
    prevName.current = fullName;
    setOpenNameModal(false);
  };

  const handleNewsletterChange = (event) => {
    setNewsletterChecked(event.target.checked);
  };

  async function handleAccount() {
    setError("");
    try {
      updateSpeciality(currentUser.uid, userSpeciality);
      updateCountry(currentUser.uid, userCountry);
      updateName(currentUser.uid, fullName);
      updateNewsletter(currentUser.uid, newsletterChecked);

      navigate(dashboard);
    } catch {
      setError("Failed to register");
    }
  }

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <div className="login_main">
        <div className="login justify_content_initial">
          <div className="personal_info_screen">Personal information</div>

          <>
            <div className="margin_top_0 text_align_center">
              <h2>Tell us about yourself</h2>
              <div>This is initial information of your profile.</div>
              <div>You can change it anytime</div>
            </div>
            <div className="text_align_center padding_10">
              <div className="full_name_div">
                <div className="width_100">
                  <button
                    onClick={() => {
                      setOpenNameModal(true);
                    }}
                    className="button_no_style account_data"
                  >
                    {fullName !== " "
                      ? fullName
                      : displayName !== ""
                      ? displayName
                      : "Enter your full name"}
                  </button>
                </div>{" "}
              </div>
              <Modal
                open={openNameModal}
                onClose={() => {
                  setOpenNameModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styleee}>
                  <div className="full_name_label">
                    <label className="label">Your Full Name</label>
                    <textarea
                      value={fullName !== " " ? fullName : ""}
                      onChange={(e) => {
                        setFullName(e.target.value);
                      }}
                    ></textarea>
                    <div className="cancel_button">
                      <button
                        className="button_no_style"
                        onClick={() => {
                          setFullName(prevName.current);
                          setOpenNameModal(false);
                        }}
                      >
                        Cancel
                      </button>
                      {fullName ? (
                        <button
                          className="button_no_style update_personal_info"
                          onClick={() => {
                            handleNameChange();
                          }}
                        >
                          Update
                        </button>
                      ) : (
                        <div>
                          <button
                            className="button_no_style font_weight_600"
                            onClick={() => {
                              setError("Invalid full name!");
                            }}
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {!fullName && (
                    <div>
                      {error && (
                        <div className="error_personal_info">{error}</div>
                      )}
                    </div>
                  )}
                </Box>
              </Modal>

              <div className="select_country_div">
                <div className="width_100">
                  <button
                    onClick={() => {
                      setOpenCountryModal(true);
                    }}
                    className="button_no_style account_data"
                  >
                    {userCountry !== "" && userCountry !== undefined
                      ? userCountry
                      : displayCountry !== "" && displayCountry !== undefined
                      ? displayCountry
                      : "Enter your country"}
                  </button>
                </div>{" "}
                <Modal
                  open={openCountryModal}
                  onClose={() => {
                    setOpenCountryModal(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleee}>
                    <div className="full_name_label">
                      <label className="label">Your Country</label>
                      <div>
                        <select
                          className="select_country"
                          onChange={(e) => {
                            setUserCountry(e.target.value);
                          }}
                          value={userCountry}
                        >
                          <option value={userCountry}>{userCountry}</option>
                          {countries.map((country, index) => (
                            <option key={index} value={country.name.common}>
                              {country.name.common}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="cancel_button">
                        <button
                          className="button_no_style"
                          onClick={() => {
                            setUserCountry(prevCountry.current);
                            setOpenCountryModal(false);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          className="button_no_style update_personal_info"
                          onClick={() => {
                            handleCountryChange();
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
              <div className="full_name_div">
                <div key={"specialty"} className="width_100">
                  <button
                    onClick={() => {
                      setOpenSpecialtyModal(true);
                    }}
                    className="button_no_style account_data"
                  >
                    {userSpeciality !== "" && userSpeciality !== undefined
                      ? userSpeciality
                      : displaySpeciality !== "" &&
                        displaySpeciality !== undefined
                      ? displaySpeciality
                      : "Enter your speciality"}
                  </button>
                </div>
                <Modal
                  open={openSpecialtyModal}
                  onClose={() => {
                    setOpenSpecialtyModal(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleee}>
                    <div className="full_name_label">
                      <label className="label">Your speciality</label>

                      <div>
                        <select
                          className="select_country"
                          onChange={(e) => {
                            setUserSpeciality(e.target.value);
                          }}
                          value={userSpeciality}
                        >
                          <option value={userSpeciality}>
                            {userSpeciality}
                          </option>
                          {specialities.map((speciality, index) => (
                            <option key={index} value={speciality}>
                              {speciality}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="cancel_button">
                        <button
                          className="button_no_style"
                          onClick={() => {
                            setUserSpeciality(prevSpeciality.current);
                            setOpenSpecialtyModal(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="button_no_style update_personal_info"
                          onClick={() => {
                            handleSpecialityChange();
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
              <div className="checkbox_info">
                <Checkbox
                  checked={newsletterChecked}
                  onChange={handleNewsletterChange}
                  sx={{
                    "&.Mui-checked": {
                      color: "#8F4FFF",
                    },
                  }}
                  inputProps={{ "aria-label": "News" }}
                />
                <div className="checkbox_text">
                  I would like to receive updates from Custom Surgical
                </div>
              </div>
              <button className="login-button" onClick={handleAccount}>
                Continue
              </button>
              <div>
                By creating an account you agree to our{" "}
                <a
                  href="https://customsurgical.co/terms-conditions/"
                  target="_blank"
                  className="update_personal_info"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://customsurgical.co/privacy-policy/"
                  target="_blank"
                  className="update_personal_info"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};
export default Account;
